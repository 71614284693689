import Helmet from "react-helmet"
import React from "react"
import Data from "./data/data.json"
import Styles from "./css/saf.module.scss"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function SafThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
     footer {
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: absolute;
        left:0;
        bottom:0;
        color: #333;
        border-radius: 5px;
        width: 100%;
      }
      footer ul {
        position: relative;
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: right;
        color: #333;
        font-size: 12px;
        padding: 12px 0;
      }
      footer ul li {
        padding: 0 4px 0 0;
      }
      footer ul li:last-child {
        padding: 0;
      }
      footer ul li:after {
        content: '|';
        padding: 0 0 0 4px;
      }
      footer ul li:nth-child(6):after, footer ul li:nth-child(7):after, footer ul li:last-child:after {
        content: none;
        padding: 0;
      }
      footer ul a {
        color: #333;
      }
      @media only screen and (max-width: 768px) {
    
        footer {
          position: relative;
          display: flex;
          justify-content:center;
          align-items:center;
          color: #333;
          width: 100%;
          right:auto;
          bottom:auto;
      }
    
      footer ul {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    }
    `}
    </style>
    <title>Thank you for installing EasyPrint</title>
    </Helmet>

    <section className={Styles.safContainer}>
        <img className={Styles.logo} src="/img/easyprint-logo.png" alt="Easy Print"></img>
        <h1>Print any web page easily.</h1>
        <h2>Integrated with an intuitive search engine powered by Yahoo Search! in 2 easy steps.</h2>
        <div className={Styles.steps}>
            <article className={Styles.step1}>
            <h3>Step 1</h3>
            <h4>Locate and Click the Easy Print Extension Icon to the left of the address bar</h4>
            <img src="/img/step1.jpg" alt="Easy Print"></img>
            </article>
            <article className={Styles.step2}>
            <h3>Step 2</h3>
            <h4>Then Click <span>"Always Allow on Every Website"</span> for the best EasyPrint experience</h4>
            <img src="/img/step2.jpg" alt="Easy Print"></img>
            </article>
        </div>
        <RawFooter></RawFooter>
    </section>
    </>
  )
}